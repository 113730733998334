import { APIFilter } from '@/utils/api'

export default {
  async selectClienteAviso (Vue, idcliente, idsistema, esTecnico, esComercial, esVigilante) {
    const resp = await Vue.$api.call(
      'clienteAviso.cursorAvisos', {
        idcliente: idcliente,
        idsistema: idsistema,
        imprime_comercial: esComercial,
        imprime_sat: esTecnico,
        imprime_admon: null
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectClienteAvisoRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idcliente_aviso', pks)
    const resp = await Vue.$api.call('clienteAviso.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
